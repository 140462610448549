
<div class="dashboard-grid">
    <div v-if="kpiData" class="tile tile-1">
        <h2>Recent Sales</h2>
        <div class="vert-grid">
            <div v-for="(sale, index) in kpiData.recentSales" :class="{'border-bottom': index < kpiData.recentSales.length - 1}" class="py-2 px-2 position-relative">
                <div class="d-flex justify-content-between">
                    <div>
                        <div class="font-small">{{ Date.fromISOString(sale.creationDate).formatDateTime() }}</div>
                        <b class="accent-color">{{ sale.companyName|truncate(30) }}</b>
                        <div class="font-small" v-if="sale.aanbrenger !== 'Website'">Sold by <b>{{ sale.aanbrenger }}</b></div>
                    </div>
                    <div class="text-right">
                        <div class="font-big font-weight-bold mb-1">€ {{sale.price.formatThousandSeparator()}}</div>
                        <div :class="getBadgeColor(sale.factuurType)" class="badge badge-small">{{ sale.factuurType }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-if="kpiData" class="tile tile-2">
        <h2>Upcoming Webmeetings</h2>
        <div class="vert-grid">
            <div v-for="(demo, index) in kpiData.upcomingWebmeetings" :class="{'border-bottom': index < kpiData.upcomingWebmeetings.length - 1}" class="py-2 px-2 position-relative">
                <div class="font-small">{{ Date.fromISOString(demo.date).formatDateTime() }}</div>
                <b class="accent-color">{{ demo.companyName|truncate(35) }}</b>
                <div class="font-small">{{ demo.salesOperator }}</div>
            </div>
        </div>
    </div>
    
    <div class="tile tile-3">
        <h2>Sales revenue</h2>
        <div>
            <canvas id="salesCanvas" ref="salesCanvas" class="pb-3"></canvas>
        </div>
    </div>

  
    <div class="tile tile-4">
        <h2>Planner KPI's</h2>
        <div>
            <canvas id="plannerCanvas" ref="plannerCanvas"></canvas>
        </div>
    </div>

    <div v-if="kpiData" class="tile tile-5 tile-centered">
        <h2> 1.100.000 EUR revenue target</h2>
        <div class="big-text" :class="{'green': kpiData.annualTurnoverProgression >= 100, 'red': kpiData.annualTurnoverProgression < 100}">
            {{kpiData.annualTurnoverProgression}} %
        </div>

        <div v-if="isManager" class="progression-form">
            <form v-on:submit.prevent="saveProgression">
                <input type="number" v-model.number="newProgression" class="form-control">
            </form>
        </div>
    </div>
    <div class="tile tile-6" v-if="kpiData">
        <h2>Datateam</h2>
        <div class="vert-grid">
            <div class="d-flex justify-content-between">
                <div class="mandates-title">Active</div>
                <div class="mandates-data green">{{kpiData.mandatesKpi.find(x => x.active === true).filledWithBirthDate.formatThousandSeparator()}} / {{kpiData.mandatesKpi.find(x => x.active == true).total.formatThousandSeparator()}}</div>
                <div class="mandates-percent">
                    {{(kpiData.mandatesKpi.find(x => x.active == true).filledWithBirthDate/kpiData.mandatesKpi.find(x => x.active == true).total * 100).toFixed(2)}} %
                </div>
            </div>
            <div class="d-flex justify-content-between">
                <div class="mandates-title">Inactive</div>
                <div class="mandates-data red">{{kpiData.mandatesKpi.find(x => x.active === false).filledWithBirthDate.formatThousandSeparator()}} / {{kpiData.mandatesKpi.find(x => x.active == false).total.formatThousandSeparator()}}</div>
                <div class="mandates-percent">
                    {{(kpiData.mandatesKpi.find(x => x.active == false).filledWithBirthDate/kpiData.mandatesKpi.find(x => x.active == false).total * 100).toFixed(2)}} %
                </div>
            </div>
        </div>
        <div class="mt-5">
            <canvas id="dagverwerkingCanvas" ref="dagverwerkingCanvas"></canvas>
        </div>
    </div>
    <div class="text-center font-small position-absolute bottom-0">Last update: {{lastUpdate.formatDateTime()}}</div>
</div>
